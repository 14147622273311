var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uploader"},[(_vm.showType == 'avatar')?_c('el-upload',{style:({
      width: parseFloat(_vm.width) + 'px',
      '--width': _vm.imgWidth,
      '--line-height': _vm.getLineHeight(_vm.imgWidth)
    }),attrs:{"action":"/api/v1/pri/file/upload","list-type":"picture-card","headers":{
                     'token':_vm.token
                     },"on-success":_vm.uploadSuccess,"on-remove":_vm.uploadRemove,"limit":_vm.limitNum,"on-exceed":_vm.exceed,"file-list":_vm.fileList,"on-preview":_vm.onPreviewImg,"disabled":_vm.isDisabled,"before-upload":_vm.beforeUpload}},[_c('i',{staticClass:"el-icon-plus"})]):_vm._e(),(_vm.showType == 'button')?_c('el-upload',{attrs:{"action":"/api/v1/pri/file/upload","on-success":_vm.uploadSuccess,"on-remove":_vm.uploadRemove,"limit":_vm.limitNum,"on-exceed":_vm.exceed,"show-file-list":_vm.showFileList,"on-preview":_vm.onPreviewImg,"before-upload":_vm.beforeUpload,"disabled":_vm.isDisabled}},[_c('el-button',{attrs:{"disabled":_vm.isDisabled,"size":"small","icon":"el-icon-plus","type":"primary"}},[_vm._v(" 添加 ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }