// 创建axios实例
import axios from 'axios'
const service = axios.create({
  baseURL: "/",
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json"
    config.headers["token"] = window.localStorage.getItem('token')
    return config;
  },
  (error) => {
    console.log(error); // for debug
  }
);
// response 拦截器
service.interceptors.response.use(
  (response) => {
    const code = response.data.code;
    console.log(response, '111')
    if (code === 0 || code === 200 || response.data) {

      return response.data; // 请求成功回调
    } else {
      return Promise.reject("error");
    }
  },
  (error) => {
    console.log(error, '222')
    return Promise.reject(error);
  }
);

export { service as request };
