<template>
  <div class="uploader">
    <el-upload v-if="showType == 'avatar'"
               action="/api/v1/pri/file/upload"
               list-type="picture-card"
               :headers="{
                       'token':token
                       }"
               :on-success="uploadSuccess"
               :on-remove="uploadRemove"
               :limit="limitNum"
               :on-exceed="exceed"
               :file-list="fileList"
               :on-preview="onPreviewImg"
               :disabled="isDisabled"
               :before-upload="beforeUpload"
               :style="{
        width: parseFloat(width) + 'px',
        '--width': imgWidth,
        '--line-height': getLineHeight(imgWidth)
      }">
      <i class="el-icon-plus" />
    </el-upload>
    <el-upload v-if="showType == 'button'"
               action="/api/v1/pri/file/upload"
               :on-success="uploadSuccess"
               :on-remove="uploadRemove"
               :limit="limitNum"
               :on-exceed="exceed"
               :show-file-list="showFileList"
               :on-preview="onPreviewImg"
               :before-upload="beforeUpload"
               :disabled="isDisabled">
      <el-button :disabled="isDisabled"
                 size="small"
                 icon="el-icon-plus"
                 type="primary">
        添加
      </el-button>
    </el-upload>
  </div>
</template>

<script>
import mixins from "element-ui/lib/mixins/emitter.js";
export default {
  name: "YUploadimg",
  mixins: [mixins],
  props: {
    accept: {
      type: String,
      default: ''
    },
    showType: {
      type: String,
      default: "avatar" //avatar  button按钮
    },
    imgWidth: {
      //单个上传图片宽度
      type: String,
      default: "100px"
    },
    multiple: {
      type: Boolean,
      default: true
    },
    showFileList: {
      //是否禁用上传
      type: Boolean,
      default: false
    },
    annexId: {
      //上传的文件id
      type: String,
      default: ""
    },
    isDisabled: {
      //是否禁用上传
      type: Boolean,
      default: false
    },
    limitNum: {
      //最大允许上传个数
      type: Number,
      default: null
    },
    type: {
      //不同文件区分标识
      type: String,
      default: ""
    },
    width: { type: String, default: "400px" } //上传组件宽度
  },
  data () {
    return {
      annexIds: "",
      annexIdsList: [],
      fileList: [],
      token: window.localStorage.getItem('token'),
    };
  },
  watch: {
    annexId: {
      handler (val) {
        this.fileList = [];
        this.annexIdsList = [];
        if (val) {
          this.annexIds = val;
          this.annexIdsList = val.split(",");
          this.annexIdsList.forEach(item => {
            let obj = {
              url: item,
              id: item
            };
            this.fileList.push(obj);
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () { },
  methods: {
    beforeUpload (file) {
      console.log(this.accept, file.name)
      if (this.accept) {

      }
    },
    uploadSuccess (response) {
      if (this.multiple) {
        this.annexIdsList.push(response.data);

      } else {
        this.annexIdsList = [response.data];
      }
      this.$emit("update:annexId", this.annexIdsList.toString());
      this.$emit("uploadSuccess", this.annexIdsList.toString(), this.type);
      this.dispatch("ElFormItem", "el.form.blur", [this.annexId]);
    },
    uploadRemove (file) {
      let id = file.id || (file.response && file.response.data[0].id);
      let index = this.annexIdsList.indexOf(id);
      if (index != -1) {
        this.annexIdsList.splice(index, 1);
      }
      // this.annexIds = this.annexIdsList.toString();
      this.$emit("update:annexId", this.annexIdsList.toString());
      this.$emit("uploadRemove", this.annexIdsList.toString(), this.type);
      this.dispatch("ElFormItem", "el.form.blur", [this.annexId]);
    },
    onPreviewImg (file) {
      let annexIdsList = this.annexIdsList;
      let index = annexIdsList.indexOf(file.id || file.response.data[0].id);
      this.$imgView({
        urlList: annexIdsList.map(
          item => this.$imgAnnexUrl + item
        ),
        initialIndex: index
      });
    },
    exceed () {
      if (!this.limitNum) {
        return;
      }
      this.$message({
        type: "error",
        message: "附件最多只能上传" + this.limitNum + "个"
      });
    },
    getLineHeight (val) {
      let lineHeight = Number(val.slice(0, -2)) + 10;
      return lineHeight + "px";
    }
  }
};
</script>

<style scoped >
.uploader >>> .el-upload-list--picture-card .el-upload-list__item {
  width: var(--width);
  height: var(--width);
}
.uploader >>> .el-upload--picture-card {
  width: var(--width);
  height: var(--width);
  line-height: var(--line-height);
}
</style>
