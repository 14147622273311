import functionList from "./function";
export default {
  //导出方法
  exportData(data) {
    if (data.method == "get") {
      var result = functionList.queryParam(data.query);
      var path = data.url + result;
      window.open(path, "_blank");
    }
  },
};
