<template>
  <div class="container">
    <div class="left">
      <ul>
        <li v-for="(item,index ) in cateList"
            :class="{activeMenu:item.id==query.cateId}"
            :key="index"
            @click="getMenu(item)">
          <i class="iconfont"
             v-html='item.icon'></i>
          {{item.name}}
        </li>

      </ul>
    </div>
    <div class="middle">
      <div class="list-header">
        <div class="nav-list">
          <div class="nav-item active">推荐
            <div class="splitLine"></div>
          </div>
          <div class="nav-item">最新
            <div class="splitLine"></div>
          </div>
        </div>
      </div>
      <ul>
        <li v-for="(item,index) in articleList"
            :key="index"
            @click="detail(item)">
          <div class="content">
            <div class="title">
              {{item.title}}
            </div>
            <div class="summary">
              {{item.summary}}
            </div>
            <div class="info">
              <div class="infoLeft">
                <div class="user">源码课堂</div>
                <div class="view">
                  <i class="iconfont"
                     style="font-size:18px">&#xe600;</i>
                  {{item.viewNum}}
                </div>
                <div class="zan">
                  <i class="iconfont"
                     style="font-size:14px">&#xe885;</i>
                  {{item.zanNum}}
                </div>
                <div class="zan">
                  {{item.createTime}}
                </div>
              </div>
              <div class="infoRight">
                <div class="tagList">
                  <div class="tag"
                       v-for="(child,childindex) in item.tagList"
                       :key="childindex">{{child.name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="imgBox"
               v-if="item.mainImg">
            <img :src="item.mainImg"
                 alt="">
          </div>
        </li>
      </ul>
      <el-skeleton :rows="6"
                   animated
                   v-if="loading"
                   style="margin:0 20px;" />
      <el-empty description="~~没有数据,请看其他页面~~"
                v-if="empty"></el-empty>
    </div>
    <div class="right">
      <div class="newArticle">
        <div class="newArticleTitle">
          最新榜
        </div>
        <ul>
          <li class="one"
              v-for="(item,index) in newArticleList"
              :key="index"
              @click="detail(item)">
            <span>{{index+1}}</span>
            {{item.title}}
          </li>

        </ul>
        <!-- <div class="more">
          查看更多<i class="iconfont"
             style="font-size:11px;margin-left:4px;">&#xe886;</i>
        </div> -->
      </div>
      <div class="newArticle jxArticle">
        <div class="newArticleTitle ">
          精选文章
        </div>
        <ul>
          <li class="two"
              v-for="(item,index) in recommendList"
              :key="index"
              @click="detail(item)">
            <p class="articleTitle">
              {{item.title}}
            </p>
            <p data-v-2d1c4a78=""
               class="count">
              {{item.zanNum||'0'}}赞 ·
              {{item.viewNum||'0'}}浏览
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'IndexView',
  data () {
    return {
      menuIndex: 0,
      loading: false,
      cateList: [

      ],
      empty: false,
      query: {
        pageNo: 1,
        pageSize: 10,
        cateId: '',
      },
      total: 1,
      articleList: [],
      recommendList: [],
      newArticleList: []
    }
  },
  mounted () {
    //标题搜索
    this.$eventBus.$off()
    this.$eventBus.$on('search', data => {
      this.query.title = data
      this.query.pageNo = 1
      this.articleList = []
      this.getArticle()
    })
    window.addEventListener('scroll', () => {
      var scrollHeight = document.documentElement.scrollHeight;
      var clientHeight = window.innerHeight;
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log('已经滚动到底部！', this.query, this.total);
        if (this.query.pageNo <= this.total) {
          this.getArticle()
        }
      }
    });
    this.getArticle()
    this.getCateList()
    this.getRecommend()
    this.getNewArticle()
  },
  methods:
  {
    //根据分类获取
    getMenu (item) {
      this.query.cateId = item.id
      this.query.pageNo = 1
      this.articleList = []
      this.empty = false
      this.getArticle()
    },
    //最新文章
    getNewArticle () {
      this.$http({
        url: '/api/v1/pri/article/getNewArticle'
      }).then(res => {
        this.newArticleList = res.data
        console.log(res.data)
      })
    },
    //推荐文章
    getRecommend () {
      this.$http({
        url: '/api/v1/pri/article/recommend'
      }).then(res => {
        this.recommendList = res.data
        console.log(res.data)
      })
    },
    //文章列表
    getCateList () {
      this.$http({
        url: '/api/v1/pri/cate/listCate'
      }).then(res => {
        this.cateList = res.data
      })
    },
    getArticle () {
      this.loading = true
      this.$http({
        url: '/api/v1/pri/article/listArticle',
        params: { ...this.query }
      }).then(res => {
        setTimeout(() => {
          this.loading = false
          this.articleList = this.articleList.concat(res.data.list)
          this.query.pageNo++
          this.total = Math.ceil(res.data.total / this.query.pageSize)
          if (res.data.total == 0) {
            this.empty = true
          }
        }, 800)
      })
    },
    detail (item) { this.$router.push({ path: '/detail?id=' + item.id }) },
    routerPush (url) {
      this.$router.push({ path: url })
    }
  }
}
</script>
<style scoped>
.left {
  width: 180px;
  border-radius: 5px;
}
.left li {
  cursor: pointer;
}
.right {
  width: 260px;
}
.middle {
  flex: 1;
  border-radius: 5px;
  margin: 0 20px;
  min-height: calc(100vh - 95px);
  background: #fff;
}
.newArticle {
  background: #fff;
  padding: 12px 8px 0;
  margin-bottom: 20px;
}
.jxArticle ul {
  padding-bottom: 15px;
}
.more {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8a919f;
  border-top: 1px solid #f1f2f5;
}
.newArticle li.one {
  height: 38px;
  line-height: 38px;
  color: #252933;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.newArticle li.two {
  margin-top: 10px;
  padding: 0 10px;
}
.newArticle li.two .articleTitle {
  transition: color 0.3s;
  font-size: 14px;
  line-height: 24px;
  color: #252933;
  margin: 0;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.count {
  padding-top: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #8a919f;
  margin: 0;
}
.newArticle li span {
  margin: 0 10px;
  font-family: fantasy;
}
.newArticle li:nth-of-type(1) span {
  background: linear-gradient(180deg, #2080f7 20%, #41c2f7 80%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.newArticle li:nth-of-type(2) span {
  background: linear-gradient(180deg, #f64242 20%, rgba(246, 66, 66, 0.4) 80%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.newArticle li:nth-of-type(3) span {
  background: linear-gradient(180deg, #ffac0c 20%, rgba(255, 172, 12, 0.4) 80%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.newArticleTitle {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 0 8px 12px;
  font-size: 16px;
  border-bottom: 1px solid #f1f2f5;
}
.newArticleTitle i {
  margin-right: 5px;
  color: #1e80ff;
}
.left ul {
  padding: 8px;
  background: #fff;
  border-radius: 5px;
}

.left ul li {
  padding: 10px 17px;
  color: #515767;
}
.left ul li i {
  margin-right: 12px;
}
.list-header {
  height: 48px;
  background: #fff;
  border-bottom: 1px solid #f1f2f5;
}
.nav-list {
  display: flex;
  align-items: center;
  height: 48px;
  margin-left: 20px;
}
.nav-item {
  padding: 0 10px;
  position: relative;
  height: 100%;
  line-height: 48px;
}
.splitLine {
  position: absolute;
  bottom: 5px;
  width: 20px;
  height: 2px;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
}
.active {
  color: #1e80ff;
  font-weight: bold;
}
.active .splitLine {
  background: #1e80ff;
}
.middle ul {
  padding: 12px 20px;
  border-radius: 5px;
  background: #fff;
}
.middle ul li {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #f1f2f5;
  width: 100%;
}
.content {
  flex: 1;
}
.imgBox {
  width: 110px;
  height: 70px;
  margin-left: 20px;
}
.imgBox img {
  width: 110px;
  height: 70px;
}
.title {
  font-weight: 600;
  line-height: 24px;
  color: #252933;
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.summary {
  color: #8a919f;
  font-size: 13px;
  line-height: 22px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.info {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}
.infoLeft {
  display: flex;
}
.user {
  color: #8a919f;
  font-size: 13px;
  margin-right: 22px;
}
.view,
.zan {
  color: #8a919f;
  margin-right: 22px;
}
.tagList {
  display: flex;
}
.tag {
  background-color: #f2f3f5;
  padding: 0 6px;
  border-radius: 2px;
  max-width: 76px;
  font-size: 12px;
  box-sizing: border-box;
  margin-left: 6px;
  color: #8a919f;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-height: 18px;
  line-height: 18px;
}

.infoRight ul {
  display: flex;
}
.activeMenu {
  color: #1e80ff !important;
  background: #eaf2ff;
  border-radius: 5px;
}
.left ul li:hover {
  color: #1e80ff;
  background: #f7f8fa;
}
</style>
