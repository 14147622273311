import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { request } from '@/utils/request'
import VueRouter from 'vue-router'
Vue.config.productionTip = false
Vue.prototype.$http = request
Vue.use(ElementUI);

import yui from "./components/yui";
import '@/components/yui/css/index.css'
import "@wangeditor/editor/dist/css/style.css"

import eventBus from '@/utils/eventBus'
Vue.prototype.$eventBus = eventBus;
Vue.use(yui);
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
