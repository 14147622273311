import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      requireAuth: true,
    },
    component: () => import('@/views/login.vue')
  },
  {
    path: '/editor',
    name: 'editor',
    meta: {
      requireAuth: true,
    },
    component: () => import('@/views/editor.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/detail.vue')
  },
  {
    path: '/class',
    name: 'class',
    component: () => import('@/views/class.vue')
  },
  {
    path: '/gzh',
    name: 'gzh',
    component: () => import('@/views/class.vue')
  },
  {
    path: '/xcx',
    name: 'xcx',
    component: () => import('@/views/class.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('token')
  console.log(!token && to.path !== '/login', '!token && to.path ')
  if (!token && to.path !== '/login') {
    console.log(to.meta, to.meta.requireAuth)
    if (to.meta.requireAuth) {
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
})


export default router
