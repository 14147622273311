import moment from "moment";
import test from "./test";
export default {
  //转换下拉数据格式
  modifyData (data) {
    return data.filter((item) => {
      if (!item.label && item.text) {
        item.label = item.text;
      }
      if (item.target) {
        for (var k in item.target) {
          if (k == "nodes") {
            continue;
          }
          item[k] = item.target[k] === "" ? null : item.target[k];
        }
        delete item.target;
      }
      if (item.attributes) {
        for (var k in item.attributes) {
          if (item.attributes[k]) {
            item[k] = item.attributes[k];
          }
        }
      }
      if (!item.children && item.nodes) {
        item.children = item.nodes;
        delete item.nodes;
      }
      if (item.children && item.children.length) {
        this.modifyData(item.children);
      } else if (!item.children) {
        delete item.children;
      }
      return true;
    });
  },
  //深拷贝
  /**
   * @description 深度克隆
   * @param {object} obj 需要深度克隆的对象
   * @returns {*} 克隆后的对象或者原值（不是对象）
   */
  deepClone (obj) {
    // 对常见的“非”值，直接返回原来值
    if ([null, undefined, NaN, false].includes(obj)) return obj;
    if (typeof obj !== "object" && typeof obj !== "function") {
      // 原始类型直接返回
      return obj;
    }
    const o = test.array(obj) ? [] : {};
    for (const i in obj) {
      if (obj.hasOwnProperty(i)) {
        o[i] = typeof obj[i] === "object" ? this.deepClone(obj[i]) : obj[i];
      }
    }
    return o;
  },
  /**
 * 表单对象赋值:
 * 对目标对象存在且源对象同样存在的属性，全部覆盖；
 * 目标对象不存在但是源对象存在的属性， 全部丢弃；
 * 目标对象存在但是源对象不存在的属性，如果是字符串赋值为空串，其余类型赋值为undefined
 */
  recover (target, source) {
    if (target === undefined || target === null) { throw new TypeError('Cannot convert first argument to object') }
    var to = Object(target)
    if (source === undefined || source === null) { return to }
    var keysArray = Object.keys(Object(target))
    for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
      var nextKey = keysArray[nextIndex]
      var desc = Object.getOwnPropertyDescriptor(target, nextKey)
      if (desc !== undefined && desc.enumerable) {
        if (to.hasOwnProperty(nextKey)) {
          if (to[nextKey] instanceof Array) {
            to[nextKey] = source[nextKey]
          } else if (to[nextKey] instanceof Object) {
            this.recover(to[nextKey], source[nextKey])
          } else if (source[nextKey] !== undefined) {
            to[nextKey] = source[nextKey]
          } else if (typeof (to[nextKey]) === 'string') {
            to[nextKey] = ''
          } else {
            to[nextKey] = undefined
          }
        }
      }
    }
    return to
  },

  /**
   * 表单对象赋值:
   * 对目标对象存在且源对象同样存在的属性，全部覆盖；
   * 目标对象不存在但是源对象存在的属性， 全部丢弃；
   * 目标对象存在但是源对象不存在的属性，保留目标对象的属性不做处理
   */
  recoverNotNull (target, source) {
    if (target === undefined || target === null) { throw new TypeError('Cannot convert first argument to object') }
    var to = Object(target)
    if (source === undefined || source === null) { return to }
    var keysArray = Object.keys(Object(target))
    for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
      var nextKey = keysArray[nextIndex]
      var desc = Object.getOwnPropertyDescriptor(target, nextKey)
      if (desc !== undefined && desc.enumerable) {
        if (to.hasOwnProperty(nextKey)) {
          if (to[nextKey] instanceof Array) {
            to[nextKey] = source[nextKey]
          } else if (to[nextKey] instanceof Object) {
            this.recover(to[nextKey], source[nextKey])
          } else if (source[nextKey] !== undefined) {
            to[nextKey] = source[nextKey]
          }
        }
      }
    }
    return to
  },
  //根据一个对象复制需要的字段，obj原始对象，result
  copyKey (obj, result) {
    // var newobj
    // if (Array.isArray(obj)) {
    //   newobj = [];
    // } else {
    //   newobj = {};
    // }
    for (var i in obj) {
      if (typeof obj[i] == "object") {
        // newobj[i] = {}
        for (var j in result[i]) {
          // newobj[i][j] = obj[i][j]
          result[i][j] = obj[i][j]
        }
        this.copyKey(obj[i], result[i]);
      } else {
        if (result && result.hasOwnProperty(i)) {
          // newobj[i] = obj[i];
          result[i] = obj[i]
        }
      }
    }
    // return newobj;
  },
  //格式化时间日期
  parseTime (time, cFormat) {
    if(time&&time.length>=17){
      // 20230710130130765
      var year=time.slice(0,4)
      var month=time.slice(4,6)
      var day=time.slice(6,8)
      var hour=time.slice(8,10)
      var min=time.slice(10,12)
      var sce=time.slice(12,14)
      time=`${year}-${month}-${day} ${hour}:${min}:${sce}`
    }
    if (typeof time === "undefined" || time === null || time === "null") {
      return "";
    }
    time = moment(time).valueOf();

    if (arguments.length === 0) {
      return null;
    }
    const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
    let date;
    if (typeof time === "object") {
      date = time;
    } else {
      if (typeof time === "string" && /^[0-9]+$/.test(time)) {
        time = parseInt(time);
      }
      if (typeof time === "number" && time.toString().length === 10) {
        time = time * 1000;
      }
      date = new Date(time);
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay(),
    };
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key];
      // Note: getDay() returns 0 on Sunday
      if (key === "a") {
        return ["日", "一", "二", "三", "四", "五", "六"][value];
      }
      if (result.length > 0 && value < 10) {
        value = "0" + value;
      }
      return value || 0;
    });
    return time_str;
  },
  //url字符串拼接
  queryParam (data) {
    // var result = JSON.stringify(data)
    //   .replace(/:/g, "=")
    //   .replace(/,/g, "&")
    //   .replace(/{/g, "?")
    //   .replace(/}/g, "")
    //   .replace(/"/g, "");
    // return result;
    let params = [];
    Object.keys(data).forEach((key) => {
      let value = data[key];
      console.log('typeof value==',typeof value);
      if (typeof value === "undefined") {
        value = "";
      }
      params.push([key, value].join("="));
    });
    return "?" + params.join("&");
  },
  //获取数据字典,dictType:字典类型，value字典值，type:字典值类型，默认code，可选值id
  getDict (dictType, value, type = "code") {
    if (!dictType) {
      return;
    }
    var allDict = localStorage.getItem("dict")
      ? JSON.parse(localStorage.getItem("dict"))
      : null;

    var dictData = allDict[dictType];
    if (value) {
      if (!dictData) {
        return;
      }
      for (var i = 0; i < dictData.length; i++) {
        if (type == "code") {
          if (dictData[i].target.dictCode == value) {
            return dictData[i].text;
          }
        }
        if (type == "id") {
          if (dictData[i].id == value) {
            return dictData[i].text;
          }
        }
      }
    } else {
      return dictData;
    }
  },
  /**
   * @description 获取某个对象下的属性，用于通过类似'a.b.c'的形式去获取一个对象的的属性的形式
   * @param {object} obj 对象
   * @param {string} key 需要获取的属性字段
   * @returns {*}
   */
  getProperty (obj, key) {
    if (!obj) {
      return;
    }
    if (typeof key !== "string" || key === "") {
      return "";
    }
    if (key.indexOf(".") !== -1) {
      const keys = key.split(".");
      let firstObj = obj[keys[0]] || {};

      for (let i = 1; i < keys.length; i++) {
        if (firstObj) {
          firstObj = firstObj[keys[i]];
        }
      }
      return firstObj;
    }
    return obj[key];
  },

  /**
   * @description 设置对象的属性值，如果'a.b.c'的形式进行设置
   * @param {object} obj 对象
   * @param {string} key 需要设置的属性
   * @param {string} value 设置的值
   */
  setProperty (obj, key, value) {
    if (!obj) {
      return;
    }
    // 递归赋值
    const inFn = function (_obj, keys, v) {
      // 最后一个属性key
      if (keys.length === 1) {
        _obj[keys[0]] = v;
        return;
      }
      // 0~length-1个key
      while (keys.length > 1) {
        const k = keys[0];
        if (!_obj[k] || typeof _obj[k] !== "object") {
          _obj[k] = {};
        }
        const key = keys.shift();
        // 自调用判断是否存在属性，不存在则自动创建对象
        inFn(_obj[k], keys, v);
      }
    };

    if (typeof key !== "string" || key === "") {
    } else if (key.indexOf(".") !== -1) {
      // 支持多层级赋值操作
      const keys = key.split(".");
      inFn(obj, keys, value);
    } else {
      obj[key] = value;
    }
  },
  //获取开始结束时间
  getBeginEnd (report) {
    var beginTime = "";
    var endTime = "";
    var myDate = new Date();
    var year;
    var month;
    var monthDate;
    if (report == "daily") {
      // 获取昨天
      myDate.setTime(myDate.getTime() - 24 * 3600 * 1000);
      beginTime =
        myDate.getFullYear() +
        "-" +
        (myDate.getMonth() + 1) +
        "-" +
        myDate.getDate();
      endTime = beginTime;
    } else if (report == "weekly") {
      // 获取上一周的开始和结束时间
      var weekDate = new Date(myDate.getTime() - 7 * 24 * 3600 * 1000); // 计算开始时间用
      var weekDate2 = new Date(myDate.getTime() - 7 * 24 * 3600 * 1000); // 计算结束时间用

      var day = weekDate.getDay();
      var time = weekDate.getDate() - day + (day === 0 ? -6 : 1);

      var startDate = new Date(weekDate.setDate(time));
      beginTime =
        startDate.getFullYear() +
        "-" +
        (startDate.getMonth() + 1) +
        "-" +
        startDate.getDate();
      var endDate = new Date(weekDate2.setDate(time + 6));
      endTime =
        endDate.getFullYear() +
        "-" +
        (endDate.getMonth() + 1) +
        "-" +
        endDate.getDate();
    } else if (report == "monthly") {
      // 获取上一月的开始和结束时间
      year = myDate.getFullYear();
      month = myDate.getMonth();
      if (month === 0) {
        month = 12;
        year = year - 1;
      } else if (month < 10) {
        month = "0" + month;
      }
      monthDate = new Date(year, month, 0);
      beginTime = year + "-" + month + "-01";
      endTime = year + "-" + month + "-" + monthDate.getDate();
    } else if (report == "annual") {
      // 获取去年的开始和结束时间
      year = myDate.getFullYear() - 1;
      beginTime = year + "-01-01";
      endTime = year + "-12-31";
    } else if (report == "monthnow") {
      // 获取本月的开始和结束时间
      year = myDate.getFullYear();
      month = myDate.getMonth() + 1;
      if (month === 0) {
        month = 12;
        year = year - 1;
      } else if (month < 10) {
        month = "0" + month;
      }
      monthDate = new Date(year, month, 0);
      beginTime = year + "-" + month + "-01";
      endTime = year + "-" + month + "-" + monthDate.getDate();
    }
    return [beginTime, endTime];
  },
  /**
   * 获得相对当前周AddWeekCount个周的起止日期
   * AddWeekCount为0代表当前周   为-1代表上一个周   为1代表下一个周以此类推
   * **/
  getWeekRange (AddWeekCount = 0,type="{y}-{m}-{d}") {
    //起止日期数组
    var startStop = new Array();
    //一天的毫秒数
    var millisecond = 1000 * 60 * 60 * 24;
    //获取当前时间
    var currentDate = new Date();
    //相对于当前日期AddWeekCount个周的日期
    currentDate = new Date(
      currentDate.getTime() + millisecond * 7 * AddWeekCount
    );
    //返回date是一周中的某一天
    var week = currentDate.getDay();
    //返回date是一个月中的某一天
    var month = currentDate.getDate();
    //减去的天数
    var minusDay = week != 0 ? week - 1 : 6;
    //获得当前周的第一天
    var currentWeekFirstDay = new Date(
      currentDate.getTime() - millisecond * minusDay
    );
    //获得当前周的最后一天
    var currentWeekLastDay = new Date(
      currentWeekFirstDay.getTime() + millisecond * 6
    );
    //添加至数组
    startStop.push(this.parseTime(currentWeekFirstDay, type));
    startStop.push(this.parseTime(currentWeekLastDay, type));

    return startStop;
  },
  //获取本月
  /**
   * 获得相对当月AddMonthCount个月的起止日期
   * AddMonthCount为0 代表当月 为-1代表上一个月  为1代表下一个月 以此类推
   * ***/
  getMonthRange (AddMonthCount = 0,type="{y}-{m}-{d}") {
    //起止日期数组
    var startStop = new Array();
    //获取当前时间
    var currentDate = new Date();
    var month = currentDate.getMonth() + AddMonthCount;
    if (month < 0) {
      var n = parseInt(-month / 12);
      month += n * 12;
      currentDate.setFullYear(currentDate.getFullYear() - n);
    }
    currentDate = new Date(currentDate.setMonth(month));
    //获得当前月份0-11
    var currentMonth = currentDate.getMonth();
    //获得当前年份4位年
    var currentYear = currentDate.getFullYear();
    //获得上一个月的第一天
    var currentMonthFirstDay = new Date(currentYear, currentMonth, 1);
    //获得上一月的最后一天
    var currentMonthLastDay = new Date(currentYear, currentMonth + 1, 0);
    //添加至数组
    startStop.push(this.parseTime(currentMonthFirstDay, type));
    startStop.push(this.parseTime(currentMonthLastDay, type));
    //返回
    return startStop;
  },
  //获取本年
  /**
   * 获得相对当月AddMonthCount个月的起止日期
   * AddMonthCount为0 代表当月 为-1代表上一个月  为1代表下一个月 以此类推
   * ***/
  getYearRange (AddYearCount = 0,type="{y}-{m}-{d}") {
    var y = new Date().getFullYear(); //获取年份
    y = y + AddYearCount;
	if(type=='{y}-{m}-{d'){
		return [`${y}-01-01`, `${y}-12-31`];
	}else{
		 return [`${y}-01-01 00:00:00`, `${y}-12-31 23:59:59`];
	}
   
  },
  //获取当前季度起止日期
  getQuarterRange (type="{y}-{m}-{d}") {
    let now = new Date(); //当前日期
    let startTime, endTime;
    var startStop = new Array();
    let nowYear = now.getFullYear(); //当前年
    let nowMonth = now.getMonth(); //当前月
    let quarter = Math.ceil((nowMonth + 1) / 3);
    startTime = new Date(nowYear, (quarter - 1) * 3, 1);
    endTime = new Date(nowYear, quarter * 3, 0);
    startStop.push(this.parseTime(startTime, type));
    startStop.push(this.parseTime(endTime, type));
    //返回
    return startStop;
  },
  //数字转成汉字
 toChinesNum(num) {
    let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
    let unit = ['', '十', '百', '千', '万']
    num = parseInt(num)
    let getWan = (temp) => {
        let strArr = temp.toString().split('').reverse()
        let newNum = ''
        let newArr = []
        strArr.forEach((item, index) => {
            newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index])
        })
        let numArr = []
        newArr.forEach((m, n) => {
            if (m !== '零') numArr.push(n)
        })
        if (newArr.length > 1) {
            newArr.forEach((m, n) => {
                if (newArr[newArr.length - 1] === '零') {
                    if (n <= numArr[numArr.length - 1]) {
                        newNum += m
                    }
                } else {
                    newNum += m
                }
            })
        } else {
            newNum = newArr[0]
        }

        return newNum
    }
    let overWan = Math.floor(num / 10000)
    let noWan = num % 10000
    if (noWan.toString().length < 4) {
        noWan = '0' + noWan
    }
    return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
  }
};
