<template>

  <el-dialog title="提示"
             :visible.sync="dialogVisible"
             width="30%"
             :before-close="close">
    <el-form :model="form"
             :rules="rules"
             ref="form"
             label-width="100px">
      <el-form-item label="手机号"
                    prop="phone">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="密码"
                    prop="pwd">
        <el-input v-model="form.pwd"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="login()">登录</el-button>
        <el-button @click="cancel()">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {
        phone: '',
        pwd: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    cancel () {
      this.$refs['form'].resetFields();
      this.close()
    },
    login () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$http({
            url: "/api/v1/pri/user/login",
            method: "post",
            data: this.form
          }).then(res => {
            console.log(res)
            if (res.code == 0) {
              console.log(res.data)
              window.localStorage.setItem('token', res.data)
              this.$message.success('登录成功')
              this.getUserInfo()
              this.close()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    //获取用户信息
    getUserInfo () {
      this.$http({
        url: '/api/v1/pri/user/findUser'
      }).then(res => {
        console.log(res)
      })
    },
    open () {
      this.dialogVisible = true
    },
    close () {
      this.dialogVisible = false
    }
  }
}
</script>