<template>
  <div>
    <div class="header">

      <div class="headerBox">
        <img src="@/assets/logo.png"
             alt=""
             class="logo">
        <div class="headerRight">
          <ul>
            <li v-for="(item,index) in menuList"
                :key="index"
                :class="{activeMenu:menuIndex==index}"
                @click="href(item)">
              {{item.name}}
              <div class="splitLine"></div>
            </li>
          </ul>
          <div class="searchBox">
            <input type="text"
                   placeholder="请输入关键字"
                   v-model="searchTxt"
                   @keyup.enter="search"
                   style="width:180px;">
            <div style="width:20px">
              <i class="el-icon-circle-close"
                 style="margin-right:10px;"
                 @click="clear"
                 v-if="searchTxt"></i>
            </div>
            <div style="width:20px;text-align:center">
              <i class="el-icon-search"
                 @click="search"></i>
            </div>
          </div>
          <div class="option"
               @click="login"
               v-if="!isLogin">
            <div class="login">登录</div>
            <div class="register">注册</div>
          </div>
          <div v-else
               class="userBox">
            <el-button type="primary"
                       class="writeBtn"
                       icon="el-icon-plus"
                       @click="write"
                       size="mini">写文章</el-button>
            <el-popover placement="bottom-end"
                        width="150"
                        trigger="click">
              <div class="userDialogBox">
                <el-avatar size="mini"
                           slot="reference"
                           src="https://img0.baidu.com/it/u=3786625063,3929489332&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1730653200&t=fcbabc0a9e7bed8133fca969c446e363"></el-avatar>
                <div class="userNameAndPhone">
                  <div> {{user.name}}</div>
                  <div>{{user.phone}}</div>
                </div>
              </div>
              <ul class="dialogMenuList">
                <li>
                  <i class="el-icon-user"></i>
                  个人中心
                </li>
                <li>
                  <i class="el-icon-video-camera"></i>
                  课程中心
                </li>
                <li>
                  <i class="el-icon-document"></i>
                  文章管理
                </li>
              </ul>
              <el-avatar size="mini"
                         slot="reference"
                         src="https://img0.baidu.com/it/u=3786625063,3929489332&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1730653200&t=fcbabc0a9e7bed8133fca969c446e363"></el-avatar>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="emptyHeader"></div>
    <login ref="login" />
  </div>
</template>

<script>
import login from './login.vue'
export default {
  components: { login },
  data () {
    return {
      menuIndex: -1,
      isLogin: false,
      searchTxt: "",
      user: {
        id: '',
        name: '',
        headImg: ''
      },
      menuList: [
        { name: '首页', id: '1', url: 'index' },
        { name: '课程', id: '2', url: 'class' },
        { name: '公众号', id: '3', url: 'gzh' },
        { name: '小程序', id: '4', url: 'xcx' },
      ]
    }
  },
  watch: {
    "$route": {
      handler (newData) {
        console.log(newData)
        this.getAddress(newData)
      }
    }
  },
  created () {
    this.getAddress()
    this.getUserInfo()
  },
  methods: {
    search () {
      this.$eventBus.$emit('search', this.searchTxt)
    },
    clear () {
      this.searchTxt = ''
      this.$eventBus.$emit('search', this.searchTxt)
    },
    write () {
      this.$router.push('/editor')
    },
    //获取用户信息
    getUserInfo () {
      this.$http({
        url: '/api/v1/pri/user/findUser'
      }).then(res => {
        if (res.code == 0) {
          this.isLogin = true
          this.user = res.data
        } else {
          this.isLogin = false
        }
        console.log(res.data)
      })
    },
    login () {
      this.$refs['login'].open()
    },
    href (data) {
      this.$router.push({ name: data.url })
    },
    getAddress () {
      var name = this.$route.name
      this.menuIndex = this.menuList.findIndex(item => item.url == name)
      console.log(this.$route, name)
    }
  }
}
</script>
<style scoped>
.userDialogBox {
  display: flex;
}
.userNameAndPhone {
  margin-left: 10px;
}
.dialogMenuList {
  margin-top: 10px;
}
.dialogMenuList li {
  height: 35px;
  line-height: 35px;
}
.dialogMenuList li i {
  font-size: 16px;
}
.dialogMenuList li:hover {
  background: #f5f5f5;
}
.userBox {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.writeBtn {
  margin-right: 20px;
}
.activeMenu {
  color: #007fff;
}
.option {
  display: flex;
  background: rgba(30, 128, 255, 0.05);
  border: 1px solid rgba(30, 128, 255, 0.3);
  border-radius: 4px;
  color: #007fff;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.login {
  border-right: 1px solid rgba(30, 128, 255, 0.3);
  padding: 0 8px;
  height: 16px;
  line-height: 16px;
}
.register {
  padding: 0 8px;
  height: 16px;
  line-height: 16px;
}
.searchBox {
  background: #eeeeee;
  height: 35px;
  border-radius: 35px;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-left: 30px;
}
.searchBox input {
  background: none;
  border: none;
  height: 25px;
  outline: none;
}
.header {
  height: 60px;
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
.emptyHeader {
  height: 60px;
}
.headerBox {
  width: 1200px;
  margin: 0 auto;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerRight {
  display: flex;
  align-items: center;
}
.header ul {
  display: flex;
  margin-left: 300px;
}
.header ul li {
  padding: 0 12px;
  cursor: pointer;
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
}
.splitLine {
  position: absolute;
  bottom: 15px;
  background: #fff;
  height: 2px;
  border-radius: 2px;
  width: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.header ul li:hover .splitLine {
  background: #007fff;
}
.logo {
  width: 130px;
}
</style>
