const requireContext = require.context('./components', true, /\.vue$/)
const requireAll = context => context.keys().map(context)
import functionList from "./lib/function.js";
import option from "./lib/option.js";
import debounce from "./lib/debounce";
import throttle from "./lib/throttle.js";
const $ytool = {
  ...functionList,
  ...option,
  debounce,
  throttle
};
// 添加 install 方法，在vue中调用 Vue.use(组件)
// 将自动调用 install 方法注册所有组件
const install = function(Vue) {
  if (install.installed) return;
  //注册全部组件
   requireAll(requireContext).forEach(item => {
      Vue.component(`${item.default.name}`, item.default)
    })
  //注册方法
  Vue.prototype.$ytool = $ytool;
};

// 导出所有组件
const yui = {
  install
};
export default yui;
