<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>
<script>
import Header from '@/components/header'
export default {
  components: {
    Header
  }
}
</script>
<style>
@font-face {
  font-family: "iconfont"; /* Project id 4716412 */
  src: url("//at.alicdn.com/t/c/font_4716412_w2992wetqr.woff2?t=1730641873488")
      format("woff2"),
    url("//at.alicdn.com/t/c/font_4716412_w2992wetqr.woff?t=1730641873488")
      format("woff"),
    url("//at.alicdn.com/t/c/font_4716412_w2992wetqr.ttf?t=1730641873488")
      format("truetype");
}
/* 可以自己改成自己想要的  比如yzsIconfont*/
.iconfont {
  font-family: "iconfont" !important;
  /*  样式都可以 修改  font-size: .20rem;  */
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Arial !important;
}
li {
  list-style: none;
}
body {
  background: #f2f3f5;
}
.container {
  width: 1200px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}
.smallcontainer {
  width: 960px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}
code {
  line-height: 2 !important;
  background: #f5f5f5 !important;
  border: none !important;
  border-left: 2px solid #e5e5e5 !important;
  color: #000 !important;
  text-shadow: none !important;
  border-radius: 0px !important;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: none;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 100px;
}
</style>
